import React from "react";
import logoPreto from "../../assets/images/logo-preto.png";
import { getItem } from "@utils";
import { ShowNotification, NotificationType } from "@utils";
import "./styles.css";
import { getLocalStorageItem } from "@utils";

const Lobby = ({ setScreenToShow, setUserType, setSessionId }) => {
  const handleJoinRoom = () => {
    const userType = getLocalStorageItem("USERTYPE");
    if (userType === "agr") {
      setUserType(userType);
      setSessionId(getLocalStorageItem("SESSION"));
      ShowNotification(
        NotificationType.Warn,
        "Sempre que gravar uma videoconferência precisa validar um código OTP novo",
      );
    }

    setScreenToShow("videoconferencia");
  };

  return (
    <div className="card-container">
      <div className="card-header">
        <img className="img-logo" src={logoPreto} />
      </div>

      <div className="card-bottom">
        <button
          onClick={handleJoinRoom}
          className="button-primary button-join-room"
        >
          Entrar
        </button>
      </div>
    </div>
  );
};

export default Lobby;
