const setItem = (key, data) => window.sessionStorage.setItem(key, data);
const setLocalStorageItem = (key, data) =>
  window.localStorage.setItem(key, data);
const getSessionStorageItem = (key) => window.sessionStorage.getItem(key);
const getLocalStorageItem = (key) => window.localStorage.getItem(key);
const removeItem = (key) => window.sessionStorage.removeItem(key);
const removeLocalStorageItem = (key) => window.localStorage.removeItem(key);

export {
  setItem,
  setLocalStorageItem,
  getLocalStorageItem,
  getSessionStorageItem,
  removeItem,
  removeLocalStorageItem,
};
