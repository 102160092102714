import { setLocalStorageItem, removeLocalStorageItem } from "@utils";

function clearLocalStorage() {
  try {
    removeLocalStorageItem("USERTYPE");
    removeLocalStorageItem("TOKENACFAZ");
    removeLocalStorageItem("SESSION");
    removeLocalStorageItem("osUser");
    removeLocalStorageItem("mac");
    removeLocalStorageItem("IP");
    removeLocalStorageItem("IDUSUARIO");
    removeLocalStorageItem("MAQUINA");
    removeLocalStorageItem("USUARIO");
  } catch (e) {}
}

export function listener(event) {
  clearLocalStorage();
  const { data } = event;
  setLocalStorageItem("USERTYPE", data.userType);
  setLocalStorageItem("TOKENACFAZ", data.token);
  setLocalStorageItem("SESSION", data.session);
  setLocalStorageItem("osUser", data.osUser);
  setLocalStorageItem("mac", data.mac);
  setLocalStorageItem("IP", data.ip);
  setLocalStorageItem("IDUSUARIO", data.idusuario);
  setLocalStorageItem("MAQUINA", data.maquina);
  setLocalStorageItem("USUARIO", data.usuario);
}
